import React from "react"
//
import { graphql } from "gatsby"
import Loadable from "@loadable/component"

//const = Loadable(() => import("./ShopifyBuyButton"))

const VR = Loadable(() => import("./Client"))

const Template = ({ data }) => (
  <>
    <VR sharp={ data.file.childImageSharp }/>
  </>
)

export default Template 

export const pageQuery = graphql`
  query($id: String!) {
    file(id: { eq: $id }) {
      childImageSharp {
        original {
          width
          src
          height
        }
      }
    }
  }
`
